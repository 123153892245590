import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Protected({ Component }) {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  console.log('isAuthenticated', isAuthenticated)

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/unauthorized');
    } else {
      navigate('/orders')
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? <Component /> : null;
}

export default Protected;