/** @format */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/slices/auth';

// ----------------------------------------------------------------------

const DashboardNavbar = (props) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func,
  };

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      try {
        const parsedUser = JSON.parse(user);
        setUser(parsedUser);
      } catch (error) {
        console.error('Failed to parse user token:', error);
      }
    }
  }, []);

  const handleLogout = () => {
    Cookies.remove('token');
    dispatch(logout());
    localStorage.removeItem('user');
    navigate('/');
  };

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box className="actions-left" sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
          <p className='logo'>Orders</p>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px' }}>
          <Button sx={{ textTransform: 'uppercase', fontWeight: '500' }} variant="contained" onClick={handleLogout} >
            Logout
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default DashboardNavbar;