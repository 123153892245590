import { useEffect, useState } from "react";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  CircularProgress,
  Box,
  TextField,
  InputAdornment,
  IconButton
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Page from '../../layouts/components/common/Page';
import Scrollbar from '../../layouts/components/common/Scrollbar';
import { UserListHead } from "../../sections/index";
import { getOrders, postOrder } from "../../store/services/OrdersServices";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import toast from "react-hot-toast";

//__________________________________________________________

// Table Titles
let TABLE_HEAD = [
  { id: 'id', label: '#', alignRight: false },
  { id: 'contact_name', label: 'Name', alignRight: false },
  { id: 'contact_email', label: 'Email', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'discount', label: 'Discount', alignRight: false },
  { id: 'subtotal', label: 'Subtotal', alignRight: false },
  { id: 'status', label: 'Order Status', alignRight: false },
  { id: 'lulu_id', label: 'Lulu Id', alignRight: false },
  { id: 'Action', label: 'Action', alignRight: true }
];

// Sorting
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (!Array.isArray(array)) {
    return [];
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return stabilizedThis
      .map((el) => el[0])
      .filter(
        (_Orderss) => _orders.contact_name && _orders.contact_name.toLowerCase().includes(query.toLowerCase())
      );
  }
  return stabilizedThis.map((el) => el[0]);
}

const Orders = () => {
  const [orders, setOrders] = useState([]);

  // PAgination
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageData, setPageData] = useState({
    total: 0,
    totalPages: 0,
  });

  const handleRequestSort = (event, property) => {
    const isAsc = order !== 'asc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    const query = event.target.value;
    setFilterName(query);
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;

  const filteredOrders = applySortFilter(
    orders,
    getComparator(order, orderBy),
    filterName,
  );

  const isOrdersNotFound = filteredOrders.length === 0;

  // Get Orderss
  const getOrdersApi = () => {
    setLoading(true);
    let params = {
      page: page + 1,
      limit: rowsPerPage,
      search: filterName
    }
    if (typeof orderBy == 'string') {
      params.order_by = order;
      params.column_name = orderBy;
    }
    getOrders(params)
      .then((r) => {
        if (r && r.orders) {
          setOrders(r.orders);
          let clone = Object.assign({}, r.orders);
          delete clone.data;
          setPageData(r);
          console.log("pageData", r.total)
        } else {
          setOrders([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        setOrders([]);
        setLoading(false);
      });
  };

 const printSlip = async (id) => {
  try {
    setLoading(true);
    await postOrder(id);
    toast.success('Printed Successfully');
    getOrdersApi();

  } catch (error) {
    toast.error('An error occurred');
    console.error('Failed to print slip:', error);
  } finally {
    setLoading(false);
  }
};


  useEffect(() => {
    getOrdersApi();
  }, [page, rowsPerPage, orderBy, order, filterName]);

  return (
    <>
      <Page title="Orders">
        <Container className="ContainerPagePadding">

          <Card sx={{ mt: 5 }}>
            <Box sx={{ p: 5, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
              <h2 className='h2_tag'>Orders List</h2>
              {/* <Box>
                <TextField
                  variant="outlined"
                  label="Search Orders"
                  placeholder="Search Orders"
                  value={filterName}
                  onChange={handleFilterByName}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box> */}
            </Box>
            <Scrollbar>
              <TableContainer>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {!loading &&
                      filteredOrders?.map((row, index) => {
                        const {
                          id,
                          contact_name,
                          contact_email,
                          amount,
                          discount,
                          subtotal,
                          status,
                          lulu_id                        } = row;
                        return (
                          <TableRow hover key={row.id} className="table-body">
                            <TableCell align="left">{index + 1 ?? "N/A"}</TableCell>
                            <TableCell align="left">{contact_name ?? "N/A"}</TableCell>
                            <TableCell align="left">{contact_email ?? "N/A"}</TableCell>
                            <TableCell align="left">{amount ?? "N/A"}</TableCell>
                            <TableCell align="left">{discount ?? "N/A"}</TableCell>
                            <TableCell align="left">{subtotal ?? "N/A"}</TableCell>
                            <TableCell align="left">{status ?? "N/A"}</TableCell>
                            <TableCell align="left">{lulu_id ?? "N/A"}</TableCell>
                             <TableCell align="right">
                              <LocalPrintshopIcon
                                onClick={() => printSlip(id)}
                              >
                                <CheckCircleOutlineIcon />
                              </LocalPrintshopIcon>

                            </TableCell>
                          </TableRow>
                        )
                      })}
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}
                    {!loading && filteredOrders.length == 0 && (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          No data
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={pageData.total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage="Rows per page:"
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Scrollbar>
          </Card>
        </Container>
      </Page>
    </>
  );
};
export default Orders;