/** @format */

import axios from "axios";
import toast from "react-hot-toast";
import { loginApi, logOutApi } from "../api";
import Instance from "../axios";

const API_URL = process.env.REACT_APP_API;

const login = (email, password) => {
	return Instance.post(API_URL + loginApi, {
		email,
		password,
	})
		.then((response) => {
			if (response.data) {
				localStorage.setItem("user", JSON.stringify(response.data));
			}
			return response.data;
		})
		.catch((error) => {
			console.log("login service", error)
			toast.error(error.response.data.message);
		});
};

const logout = async () => {
	const response = await axios.get(API_URL + logOutApi);
	localStorage.removeItem('user');
	return response.data;
};

const authService = {
	login,
	logout,
};

export default authService;

