/** @format **/

// React
import { Navigate, useRoutes } from 'react-router-dom';

// UnReachable
import NotFound from './pages/Page404';
import Unauthorized from './pages/Unauthorized';

// Restriction
import Protected from './layouts/components/common/Protected';

// Auth
import Login from './pages/Auth/Login';

// layout
import DashboardLayout from './layouts/dashboard';

// Product
import Orders from './pages/OrderManagement/OrdersList';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([

    // Public Routes
    // UnReachable
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: '/404', element: <NotFound /> },
    { path: '/unauthorized', element: <Unauthorized /> },

    // Auth
    { path: '/', element: <Login /> },

    // Protected
    // Dashboard Routes
    {
      element: <DashboardLayout />,
      path: '/',
      children: [

        // Product
        { path: 'orders', element: <Protected Component={Orders} /> },

      ],
    },

  ]);
}
