/** @format */

import axios from "axios";
// import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
// import { useDispatch, useSelector } from "react-redux";
// import reduxStore from 'src/store/slices/auth';
// import { logout } from "src/store/slices/auth";
// import Cookies from "js-cookie";

// const navigate = useNavigate();

const users = JSON.parse(localStorage.getItem("user"));
const user = { token: users ? users.token : "" };
// const Instance = axios.create({ baseURL: config.apiUrl });
const Instance = axios.create({ baseURL: process.env.REACT_APP_API });

// Request interceptor for API calls
Instance.interceptors.request.use(
	async (config) => {
		config.headers = {
			Accept: "application/json",
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
			"Access-Control-Allow-Headers": "Content-Type, Accept, X-Requesed-With, Origin",
			"Access-Control-Allow-Headers": "POST, GET, PUT, PATCH, OPTION, HEAD",
		};
		const user = JSON.parse(localStorage.getItem("user"));
		if (user && user.token) {
			config.headers = Object.assign(
				{
					Authorization: `Bearer ${user.token}`,
				},
				config.headers,
			);
 		}
		return config;
	},
	(error) => {
		console.log("reject error");
		Promise.reject(error);
	},
);

// Response interceptor for API calls
Instance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		// console.log("error error");
		// console.log("error axios", error.response.data.message)
		// const dispatch = useDispatch();
		//   const originalRequest = error.config;
		//   error.code == 'ERR_NETWORK'
		// console.log("error", error);
		if (error.response.status === 403 || error.response.status === 401) {
			toast.error(error.response.data.message);
			// Cookies.remove("token");
			// dispatch(logout);
			localStorage.removeItem("user");
			// window.location.replace("/");
			// window.location = "/";
		}
		// toast.error(error.response.data.message);
		return Promise.reject(error);
	},
);

export default Instance;