/** @format **/
export const urlPath = process.env.REACT_APP_API;

/*-----Login Api-----*/
export const loginApi = '/login';
export const logOutApi = '/logout';
export const cookieSanctum = '/sanctum/csrf-cookie';

/*---- Products -----*/
export const getOrdersAPI = '/orders';
export const postOrderAPI = '/orders';
