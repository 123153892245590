// ** React Imports
import { useState } from 'react'

// ** Next Imports
import { useNavigate, Link } from 'react-router-dom'

// ** MUI Components
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import CircularProgress from '@mui/material/CircularProgress';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Img
import login_bg_img from '../../assets/images/lulu_images/login_bg.png'


// ** Icons Imports
import EyeOutline from 'mdi-material-ui/EyeOutline'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'

// ** Third Party Imports
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// ** Layout Import
import BlankLayout from '../../layouts/components/common/BlankLayout'

// ** Demo Imports
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import Cookies from "js-cookie"
import { login } from "../../store/slices/auth"

const schema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().required('Password is required'),
});

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const loginLoading = useSelector((state) => state.auth.loading);
  const loginError = useSelector((state) => state.auth.error);
  const [showPassword, setShowPassword] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (token) {
      Cookies.set('token', token);
      toast.success('Login Successful');
      setTimeout(() => {
        navigate('/orders');
      }, 2000);
    } else if (loginError) {
      toast.error(loginError);
    }
  }, [token, loginError, navigate]);

  const onSubmit = async (data) => {
    dispatch(login({ email: data.email, password: data.password }));
  };

  return (
    <>
      <div className="login-main">
        <div className="login-container">
          <Box className="content-center">
            <CardContent className="card-content">
              <Box className="login-content">
                <Typography variant="h5">Welcome to the lulu Platform!</Typography>
                <Typography variant="body2">Please sign in to your account</Typography>
              </Box>

              <form novalidateautocomplete="off" onSubmit={handleSubmit(onSubmit)}>

                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Controller
                    name='email'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        label='Email'
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.email)}
                        placeholder='Your Email'
                      />
                    )}
                  />
                  {errors.email &&
                    <FormHelperText sx={{ color: 'error.main' }}>
                      {errors.email.message}
                    </FormHelperText>}
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.password)}>
                    Password
                  </InputLabel>
                  <Controller
                    name='password'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <OutlinedInput
                        value={value}
                        onChange={onChange}
                        label='Password'
                        id='auth-login-v2-password'
                        error={Boolean(errors.password)}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              edge='end'
                              onMouseDown={e => e.preventDefault()}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? <EyeOutline /> : <EyeOffOutline />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                  {errors.password && (
                    <FormHelperText sx={{ color: 'error.main' }} id=''>
                      {errors.password.message}
                    </FormHelperText>
                  )}
                </FormControl>

                <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "space-between" }}>
                  <FormControlLabel
                    label="Remember Me"
                    className="LabelWidth"
                    control={<Checkbox />}
                    sx={{ "& .MuiFormControlLabel-label": { color: "text.primary", } }}
                  />
                </Box>
                <Button className="submit-btn" type="submit">
                  {loginLoading ? <CircularProgress size={24} /> : 'LOGIN'}
                </Button>
              </form>
            </CardContent>
          </Box>
        </div>
        <img src={login_bg_img} alt="Login bg" className="loginbg" />
      </div>
    </>
  )
}
LoginPage.getLayout = page => <BlankLayout>{page}</BlankLayout>
LoginPage.guestGuard = true

export default LoginPage;