import { getOrdersAPI, postOrderAPI } from "../api";
import Instance from "../axios";

const API_URL = process.env.REACT_APP_API;



const getOrders = async (params = { limit :10, page: 1, order_by, column_name }) => {
    try {
        const response = await Instance.get(API_URL + getOrdersAPI , {params});
        return response.data;
    } catch (error) {
        throw error;
    }
};

const postOrder = async (id) => {
    try {
        const response = await Instance.put(API_URL + postOrderAPI + "/" + id);
        return response;
    } catch (error) {
        throw error;
    }
};

export {
    getOrders,
    postOrder,
};